.task_list_wrapper {
  display: flex;
  width: 100%;

  .title {
    flex-grow: 1;
    font-size: 1.1em;
    font-weight: 600;
  }

  .icon {
    cursor: pointer;
    flex-grow: 0;
    width: 40px;
    max-width: 40px;
    align-content: center;
    align-items: center;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
  }

  .icon-manage {
    cursor: pointer;
    flex-grow: 0;
    width: 100px;
    max-width: 100px;
    align-content: center;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 2px;
    padding-bottom: 2px;
    border: 1px solid #dddddd;
    text-align: center;
    background-color: #95e497;
  }

  .iconBreak {
    flex-grow: 0;
    width: 10px;
    max-width: 10px;
  }

  .icon:hover {
    background-color: #eeeeee;
  }

  .icon:active {
    position: relative;
    z-index: 1000;
    top: 2px;
    left: 2px;
  }

  .icon-manage:hover {
    background-color: #5ef48d;
  }

  .icon-manage:active {
    position: relative;
    z-index: 1000;
    top: 2px;
    left: 2px;
  }
}

.slide_task_list_wrapper {
  margin-left: 10px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 1rem;
  .title {
    flex-grow: 1;
    font-size: 1.1em;
    font-weight: 600;
    margin-top: 100px;
    margin-bottom: 30px;
  }
}

.task_detail {
  width: 100%;
  padding: 10px 15px;

  .cell {
    font-size: 0.8em;
  }
  .additional-field {
    font-size: 0.95em;
  }

  .badges {
    margin-top: 5px;
  }
}

.task_category {
  flex-grow: 1;
  font-size: 1.05em;
  font-weight: 500;
  color: var(--bs-priamry);
  margin-bottom: 12px;
  margin-top: 8px;
}

.task_button_list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.module-button {
  display: inline-block;
  flex-grow: 1;
  background-color: var(--bs-body-bg);
  box-shadow: var(--bs-box-shadow);
  border-width: 0;
  border-style: solid;
  border-left-width: 20px;
  border-radius: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 410px;
  max-width: 410px;
  min-height: 180px;
  cursor: pointer;
  box-shadow: 5px 5px 10px #dbd7d7;
  color: var(--bs-card-color);
  // padding: 0;

  .module-title {
    // word-wrap: break-word;
    background-color: var(--bs-primary-bg-subtle);
    color: var(--bs-primary-text-emphasis);
    font-size: calc(0.7em + 0.4vmax);
    font-weight: 600;
    border-top-right-radius: 10px;
    padding: 10px 15px;
    height: 70px;

    .module-title-text {
      width: 355px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
    }
  }

  .module-description {
    color: var(--bs-primary);
    padding: 10px 20px;
    min-height: 60px;
    font-size: 13px;
  }

  .additional-field-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-align: left;
    font-size: 0.8em;

    ul li {
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover {
    opacity: 0.8;
    box-shadow: 5px 5px 10px #000000;
  }

  &:active {
    position: relative;
    z-index: 1000;
    top: 4px;
    left: 4px;
  }
}

.noteWrapper {
  padding-top: 6px;
}

@mixin gridRow($bgColor) {
}

@mixin ModColor($mainColor, $bgColor, $emColor) {
  padding-bottom: 30px;
  font-size: 0.9em;

  span.note {
    background-color: $mainColor;
    color: #eeeeee;
    font-size: 1em;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  span.muteLable {
    background-color: var(--bs-danger);
    color: #eeeeee;
    font-size: 1em;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  span.badge {
    background-color: $mainColor;
    color: #fff;
    margin-left: 10px;
  }

  div.task_category {
    color: $mainColor;
  }

  div.module-button {
    border-color: $mainColor;

    .module-title {
      border-color: $mainColor;
      background-color: $bgColor;
      color: $emColor;
    }

    .module-description {
      color: $mainColor;
    }
  }
}

.Primary {
  @include ModColor(var(--bs-primary), var(--bs-primary-bg-subtle), var(--bs-primary-text-emphasis));
}

.Danger {
  @include ModColor(var(--bs-danger), var(--bs-danger-bg-subtle), var(--bs-danger-text-emphasis));
}

.Success {
  @include ModColor(var(--bs-success), var(--bs-success-bg-subtle), var(--bs-success-text-emphasis));
}

.Info {
  @include ModColor(var(--bs-info), var(--bs-info-bg-subtle), var(--bs-info-text-emphasis));
}

.Light {
  @include ModColor(var(--bs-light), var(--bs-light-bg-subtle), var(--bs-light-text-emphasis));
}

.Warning {
  @include ModColor(var(--bs-warning), var(--bs-warning-bg-subtle), var(--bs-warning-text-emphasis));
}

.Secondary {
  @include ModColor(var(--bs-secondary), var(--bs-secondary-bg-subtle), var(--bs-secondary-text-emphasis));
}

.tasklist-Success {
  background-color: var(--bs-success-bg-subtle) !important;
  color: var(--bs-success-text-emphasis) !important;
}

.tasklist-Info {
  background-color: var(--bs-info-bg-subtle) !important;
  color: var(--bs-info-text-emphasis) !important;
}

.tasklist-Secondary {
  background-color: var(--bs-secondary-bg-subtle) !important;
  color: var(--bs-secondary-text-emphasis) !important;
}

.tasklist-Warning {
  background-color: var(--bs-warning-bg-subtle) !important;
  color: var(--bs-warning-text-emphasis) !important;
}

.tasklist-Light {
  background-color: var(--bs-light-bg-subtle) !important;
  color: var(--bs-light-text-emphasis) !important;
}

.tasklist-Primary {
  background-color: var(--bs-primary-bg-subtle) !important;
  color: var(--bs-primary-text-emphasis) !important;
}

.tasklist-Danger {
  background-color: var(--bs-danger-bg-subtle) !important;
  color: var(--bs-danger-text-emphasis) !important;
}

.debugItem {
  border-top: 1px solid #903090;
  padding-top: 10px;
  padding-bottom: 10px;
  .debugRow:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .debugRow {
    width: 100%;
    clear: both;
    display: flex;

    .left {
      flex-grow: 0;
      width: 200px;
    }
    .right {
      flex-grow: 1;
    }
  }
}

.debugRowBreak {
  height: 8px;
  min-height: 8px;
  content: '&nbsp;';
}

.custom-tooltip {
  padding: 6px;
  background-color: #eeeeee;
  border: 1px solid #404040;
}

#task-list-tooltip {
  display: inline-flex;
  max-width: 500px;
  text-wrap: wrap;
  z-index: 1002;
}

.custom-modal {
  background-color: transparent !important;
}

.custom-modal-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.custom-modal-backdrop {
  opacity: 0.5 !important;
}

.desc-text {
  padding: 5px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
