body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #495057;
  font-family: Poppins, sans-serif;
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.card-container,
.tab-content .section {
  padding: 1rem;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 10px 20px 0px rgba(58, 70, 84, 0.12);
}
.text-info {
  font-size: 13px !important;
}

.all-workflow-states {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
}

button svg {
  margin-right: 12px;
}

.btn + .row {
  margin-top: 10px;
}

.btn:active {
  position: relative;
  z-index: 1000;
  top: 2px;
  left: 2px;
}

.endcap {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0 4px 4px 0 !important;
}

.workflowgap {
  flex-grow: 1;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 40px 5px 25px !important;
}

.sub-container {
  align-items: center;
  .text {
    margin-top: unset !important;
  }
}
.setting-container {
  svg {
    color: white;
  }
}

.preview-container {
  #react-doc-viewer {
    height: 85%;
    overflow-y: auto;
    #proxy-renderer {
      height: 85%;
      > div {
        height: 100%;
      }
    }
  }
}

.sb-container {
  .error {
    color: red !important;
    font-size: 11px !important;
    margin: 4px !important;
    border: 0px !important;
  }
}
.asset-details-page .action-buttons {
  gap: 5px;
  margin-top: 1rem;
}
.survey-container {
  height: 100vh;
}
