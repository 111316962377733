.react-datepicker-popper {
  z-index: 1056; // so that it can be on top of modal whose z-index=1055
}

.input-group {
  z-index: 10;
}

.input-group-append {
  .react-datepicker__close-icon {
    right: -27px;
  }
}

.disabled-datepicker {
  .react-datepicker__close-icon {
    display: none;
  }
}

.datepicker-icon {
  fill: var(--bs-body-color);
}

.width-fit-content {
  width: fit-content !important;
}
